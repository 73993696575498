

function getAssetUrl(url)
{

    return "." + url; //electron
    //return "https://bt.digiten.co.uk" + url; //webversion

}

export default getAssetUrl;