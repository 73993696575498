import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'
import './App.css';
import getAssetUrl from "./getAssetUrl"
import data from "./data.json"
import homeIcon from "./images/logo-2018.svg"

function trimText(string)
{
  if(!string)
    return ""

  const trimmed =  string.trim()
  const lastSpace = trimmed.lastIndexOf(" ");
  const newText = trimmed.substr(0, lastSpace) + "\u00A0" + trimmed.substr(lastSpace+1);
  return newText
}

function trimMarkdownSource(source)
{
  if(!source)
    return ""

  const splitMarkdown = source.split("\n")
  let newMarkdown = "";
  for(let splitM of splitMarkdown)
  {
    newMarkdown += trimText(splitM) + "\n"
  }

  return newMarkdown;
}

class App extends Component {

  constructor() {
    super();
    this.state = {data: null, section: 0, hotspot: 0, btSolution: 0, scale: 1, loaded: false, safariAgent: false}
  }

  componentDidMount() {
    var self = this;
    if (true) {
      //GET DATA FROM FILE
      this.setState({data: data.data.attributes});
      setTimeout(() => {
        self.SectionClick(self.state.data.sections.data.sort((a, b) => a.attributes.Order - b.attributes.Order)[0].id)
      }, 1000)
      setTimeout(() => {self.setState({loaded: true})}, 2000)
    } else {

      //GET DATA WEB
      fetch('https://bt.digiten.co.uk/api/main?populate[0]=mainVideo&populate[1]=sections&populate[2]=sections.BackgroundAnimation&populate[3]=sections.hotspots&populate[4]=sections.hotspots.CalloutIcon&populate[5]=sections.hotspots.BTSolution&populate[6]=sections.hotspots.BTSolution.Icon&populate[7]=sections.hotspots.BTSolution.IconInCallout')
          .then((response) => response.json())
          .then((data) => {
            self.setState({data: data.data.attributes});
            setTimeout(() => {
              self.SectionClick(self.state.data.sections.data.sort((a, b) => a.attributes.Order - b.attributes.Order)[0].id)
            }, 1000)
            setTimeout(() => {self.setState({loaded: true})}, 2000)

          });
    }

    window.addEventListener('resize', () => this.resizeContent())
    this.resizeContent();

    setTimeout(() => {
          let videos = document.querySelectorAll(".BackgroundContainer video")

          for (let video of videos)
            video.play();
        }
        , 2000)

    let userAgentString = window.navigator.userAgent
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    if ((chromeAgent) && (safariAgent)) safariAgent =false;

    this.setState({safariAgent: safariAgent})

  }


  resizeContent()
  {
    var oWidth = 1920;
    var oHeight = 1080;

    var cHeight = document.querySelector(".App").offsetHeight
    var cWidth = document.querySelector(".App").offsetWidth

    var scale = 1;

    if(cHeight / cWidth >= oHeight / oWidth)
    {
      scale = cWidth / oWidth
    }
    else
    {
      scale = cHeight / oHeight
    }

    this.setState({scale: scale})

    document.querySelector("#PageWrapper").style.transform = "translate(-50%, -50%) scale("+scale+")"
  }

  SectionClick(sectionId)
  {
    this.setState({section: sectionId, hotspot: 0, btSolution: 0})
    document.getElementById("MainContainer").classList.remove("calloutDisplayed")
  }

  HotspotClick(hotspotId)
  {
    this.setState({hotspot: hotspotId, btSolution: 0})
    setTimeout(() => {
      document.getElementById("MainContainer").classList.add("calloutDisplayed")
    }, 500)
  }

  CloseCallOut()
  {
    this.setState({hotspot: 0, btSolution: 0})
    document.getElementById("MainContainer").classList.remove("calloutDisplayed")
  }

  FindOutMoreClick(btSolutionId)
  {
    this.setState({btSolution: btSolutionId})
  }

  CloseFindOutMore()
  {
    this.setState({btSolution: 0})
  }

  ResetApp()
  {
    this.setState({section: 0, hotspot: 0, btSolution: 0})
    document.getElementById("MainContainer").classList.remove("calloutDisplayed")
  }

  render(){

    return (
        <div className={"App" + (this.state.data ? " loaded" : "")}>
          <div id="PageWrapper">
          <div className={"loading" + (this.state.loaded ? " hide" : "")} >
            <div className='circle'></div>
          </div>
            <a className="getInTouchButton" href={"https://pages.business.bt.com/Logistics.html#CenteredForm"} target={"_blank"}>Get in touch</a>
            <div className="NavPane">
              <img src={homeIcon} className="Logo" onClick={() => this.ResetApp()} />
            </div>
            <div id="MainContainer">
              <div className="BackgroundContainer">
                {this.state.data && this.state.data.mainVideo.data &&
                  <video id="mainBackground" muted={true} playsInline={true} loop>
                    <source src={getAssetUrl(this.state.data.mainVideo.data.attributes.url)}/>
                  </video>
                }
                {this.state.data && this.state.data.sections && this.state.data.sections.data.map(section => {
                  return (
                      <video id={"sectionVideo-"+section.id} className={"SectionVideo" + (this.state.section === section.id ? " active" : "")} key={"sectionVideo-"+section.id} muted={true} playsInline={true} loop>
                        <source src={getAssetUrl(section.attributes.BackgroundAnimation.data.attributes.url)}/>
                      </video>
                  )
                })}
              </div>
              <div className={"SectionMenu"}>
                {this.state.data && this.state.data.sections.data.sort((a,b) => a.attributes.Order - b.attributes.Order).map(section => {
                  return (
                      <div key={section.id}
                           onClick={() => this.SectionClick(section.id)}
                           className={"SectionButton" + (this.state.section === section.id ? " active" : "")}>
                        <p>{trimText(section.attributes.Name)}</p>
                      </div>
                  )
                })}
              </div>
              <div className={"SectionText"}>
                {this.state.data && this.state.data.sections.data.map(section => {
                  return (
                      <div key={section.id} id={"sectionText-"+section.id} className={"SectionTextContainer" + (this.state.section === section.id ? " active" : "")}>
                        <h2>{trimText(section.attributes.Title)}</h2>
                        <h4>{trimText(section.attributes.SubTitle)}</h4>
                        <p dangerouslySetInnerHTML={{__html: trimText(section.attributes.Copy).replace("*", '<span>').replace("*", '</span>')}}></p>
                      </div>
                  )
                })}
              </div>
              {this.state.data && this.state.data.sections.data.map(section => {
                return section.attributes.hotspots.data.map(hotspot => {
                  return(
                      <div key={"callout" + section.id + "-" + hotspot.id} className={"CallOut" + (this.state.section === section.id && this.state.hotspot === hotspot.id ? " active" : "") + (this.state.safariAgent ? " Safari" : "")}
                      >
                        <div className={"CloseButton"} onClick={() => {this.CloseCallOut()}} />
                        <div className={"ScrollableWrapper"}>
                          <div className={"Scrollable"}>
                            
                            <h2>{trimText(hotspot.attributes.CalloutTitle)}</h2>
                            <article><ReactMarkdown>{trimMarkdownSource(hotspot.attributes.CalloutCopy)}</ReactMarkdown></article>
                            <h3>{hotspot.attributes.BTSolution.length > 1 ? "Our solutions:" : "Our solution:"}</h3>
                            {hotspot.attributes.BTSolution.map(btSolution => {
                              return (
                                  <div key={btSolution.id} className={"BTSolution"}>
                                    <img className={"Icon"} src={btSolution.Icon.data ? getAssetUrl(btSolution.Icon.data.attributes.url) : ""} />
                                    <div className={"BTSolutionContent"}>
                                      <h5>{trimText(btSolution.Title)}</h5>
                                      <article><ReactMarkdown>{trimMarkdownSource(btSolution.Copy)}</ReactMarkdown></article>
                                      <button onClick={() => {this.FindOutMoreClick(btSolution.id)}}>Find out more</button>
                                    </div>
                                  </div>
                              )
                            })}
                          </div>
                        </div>
                        {hotspot.attributes.BTSolution.map(btSolution => {
                          return (
                              <div key={btSolution.id} className={"BTSolutionCallout" + (this.state.btSolution === btSolution.id ? " active" : "")}>
                                <div className={"CoveringPane"} />
                                <button onClick={() => {this.CloseFindOutMore()}}>Back</button>
                                <div className={"ScrollableWrapper"}>
                                  <div className={"Scrollable"}>
                                    <article>
                                      <img src={btSolution.IconInCallout.data ? getAssetUrl(btSolution.IconInCallout.data.attributes.url) : ""} className={"calloutIcon"}/><ReactMarkdown>{trimMarkdownSource(btSolution.FindOutMore)}</ReactMarkdown>
                                      {btSolution.FindOutMore2 &&
                                        <div className={"FindOutMore2"}>
                                          <img src={btSolution.IconInCallout.data ? getAssetUrl(btSolution.IconInCallout.data.attributes.url) : ""} className={"calloutIcon"}/><ReactMarkdown>{trimMarkdownSource(btSolution.FindOutMore2)}</ReactMarkdown>
                                        </div>
                                      }
                                    </article>
                                  </div>
                                </div>
                              </div>
                          )
                        })}
                      </div>
                  )
                })
              })
              }
              {this.state.data && this.state.section > 0 && this.state.data.sections.data.find(section => section.id === this.state.section).attributes.hotspots.data.sort((a,b) => a.id - b.id).map((hotspot, index) => {
                let styles = {}
                let lineStyles = {}
                switch(hotspot.attributes.LineFrom)
                {
                  case "Bottom":
                    styles = {bottom: "calc(" + (-1) * hotspot.attributes.Distance + "px + 50%)"}
                    lineStyles = {height: hotspot.attributes.Distance + "px"}
                    break;

                  case "Top":
                    styles = {top: "calc(" + (-1) * hotspot.attributes.Distance + "px + 50%)"}
                    lineStyles = {height: hotspot.attributes.Distance + "px"}
                    break;

                  case "Left":
                    styles = {left: "calc(" + (-1) * hotspot.attributes.Distance + "px + 50%)"}
                    lineStyles = {width: hotspot.attributes.Distance + "px"}
                    break;

                  case "Right":
                    styles = {right: "calc(" + (-1) * hotspot.attributes.Distance + "px + 50%)"}
                    lineStyles = {width: hotspot.attributes.Distance + "px"}
                    break;
                }

                return (
                    <div key={hotspot.id}
                         className={"HotspotButton " + hotspot.attributes.LineFrom + (this.state.hotspot === hotspot.id ? " active" : "")}
                         style={{
                           top: hotspot.attributes.Hotspot_Position_Y + "px",
                           right: hotspot.attributes.Hotspot_Position_X + "px",
                           animationDelay: (index + 1) * 200 + "ms"
                         }}
                    >
                      <span style={lineStyles} />
                      <p style={styles} onClick={() => {this.HotspotClick(hotspot.id)}}>
                        {trimText(hotspot.attributes.HotspotText)}
                      </p>
                    </div>
                )
              })}
            </div>
          </div>
          
        </div>
    );
  }

}

export default App;
